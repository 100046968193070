<template>
  <div>
    <vs-card>
      <vs-row
        vs-type="flex"
        vs-justify="space-between"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
        >
          <h5>
            <vs-chip color="primary">
              المعرف : {{ group.id }}
            </vs-chip>
          </h5>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
        >
          <h5>
            <vs-chip color="primary">
              اسم المجموعة : {{ group.name }}
            </vs-chip>
          </h5>
        </vs-col>
      </vs-row>
    </vs-card>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteMember($event, null , true)"
    />
    <vs-popup
      title="تفاصيل عضو المجموعة"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="الاسم"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم</label>
                <vs-input
                  placeholder="الاسم"
                  v-model="member.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="رقم الموبايل"
                rules="required|length:12"
                v-slot="{ errors }"
              >
                <label>رقم الموبايل</label>
                <vs-input
                  type="number"
                  placeholder="رقم الموبايل"
                  dir="ltr"
                  @click="setCountryCode()"
                  v-model="member.mobile"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>

import AgTable from '../shared-components/ag-grid/AgTable';
import AgTableBtnCell from '../shared-components/ag-grid/AgTableBtnCell.vue';
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import utilities from "@/app/shared/utilities";

const membersRepo = SharedRepositoryFactory.get('groupMembersRepository');
const groupRepo = SharedRepositoryFactory.get('groupRepository');

export default {
  name: "GroupMembersMain",
  components: {
    AgTable
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      group: Object,
      member: {
        id: null,
        name: '',
        group_id: '',
        mobile: ''
      },
      isLoading: true,
      popupActive: false
    };
  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رقم الموبايل',
          field: 'mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteMember(id, rowIndex);
            },
            editRecord: function (member) {
              self.editRecord(member);
            },
            actions: ['edit', 'delete']
          },

        },
      ];
    },
    createRecord() {
      utilities.initObjectMembers(this.member);
      this.openPopup();
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    onSubmit() {
      this.isLoading = true;
      this.member.group_id = this.$route.params.id;
      if (this.member.id !== null) {
        this.updateMember(this.member);
      } else {
        this.storeMember(this.member);
      }
    },
    // used to fire the dialog which response to edit current record in the table
    editRecord(member) {
      Object.assign(this.member, member);
      this.openPopup();
    },
    storeMember(member) {
      membersRepo.storeMember(member).then((response) => {
        this.rowData.unshift(response.data.data);
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },
    deleteMember(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      membersRepo.deleteMember(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllMembers();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    updateMember(member) {
      membersRepo.updateMember(member).then((response) => {
        let index = this.rowData.map((member) => {
          return member.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    handleResponse() {
      this.popupActive = false;
    },
    setCountryCode() {
      if (this.member.mobile === null)
        this.member.mobile = '963';
      else if (!this.member.mobile.startsWith('963', 0))
        this.member.mobile = '963' + this.member.mobile;
    },
    fetchAllMembers() {
      membersRepo.fetchAllMembers(this.$route.params.id).then((response) => {
        this.rowData = response;
        this.isLoading = false;
      });
    },
    fetchGroupById() {
      groupRepo.fetchGroupById(this.$route.params.id).then((response) => {
        this.group = response;
        this.isLoading = false;
      });
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchGroupById();
    this.fetchAllMembers();
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
